import type { VueIslandConfig } from "@solvari/common-fe/helpers";

import { initAxiosInterceptors } from "@solvari/common-fe/composables";
import { initVueIslands } from "@solvari/common-fe/helpers";
import { initFontAwesome } from "@solvari/common-fe/integrations";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";

import { initPrimeVueThemed, sModalPlugin } from "@solvari/components";
import { useI18nCustomer } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

import { leadDetails } from "@/pages/leadDetails";
import { useQueryClient } from "@/plugins/vueQuery.ts";

import "@/utils/sentry";
import "@solvari/common-fe/polyfills";

import "@/styling/index.scss";
import "@solvari/components/blade/review/review-widget/reviewScore.css";

function main() {
  initFontAwesome();

  initAxiosInterceptors();

  const pages: VueIslandConfig = {
    workplacePage: () => import("@/pages/WorkplacePage.vue"),
    workplaceLeadDetailPage: () =>
      import("@/pages/WorkplaceLeadDetailPage.vue"),
    workplaceFilterPage: () => import("@/pages/WorkplaceFilterPage.vue"),
    workplaceFiltersOverviewPage: () =>
      import("@/pages/WorkplaceFiltersOverviewPage.vue"),
    creditsOverview: () => import("@/pages/CreditsOverview.vue"),
    crmApi: () => import("@/pages/settings/CrmApi.vue"),
    createPassword: () => import("@/pages/auth/CreatePassword.vue"),
    userEmailVerification: () =>
      import("@/pages/verification/UserEmailVerification.vue"),
    thankYou: () => import("@/pages/finance/ThankYou.vue"),
    accountProblem: () => import("@/pages/auth/AccountProblem.vue"),
    companyPage: () => import("@/pages/settings/CompanyPage.vue"),
  };

  const components: VueIslandConfig = {
    inbox: () => import("@/components/inboxThread.ts"),
    leadSearch: () => import("@/components/lead-search/LeadSearch.vue"),
    walletBalance: () => import("@/components/wallet/WalletBalance.vue"),
    reviewInvite: () => import("@/components/review-invite/ReviewInvite.vue"),
    proDashboardCards: () =>
      import("@/components/dashboard/cards/DashboardCards.vue"),
    serviceCard: () =>
      import("@/components/dashboard/cards/service/ServiceCard.vue"),
    createCampaignModal: () =>
      import("@/components/modal/CreateCampaignModal.vue"),
    editProject: () => import("@/pages/project/EditProject.vue"),
    onboardingFlow: () =>
      import("@/components/onboarding-flow/OnboardingFlow.vue"),
    sBuyCreditsCard: () =>
      import("@/components/buy-credits-card/SBuyCreditsCard.vue"),
    sGalleriaThumbnail: () => import("@/components/sGalleriaThumbnail.ts"),
  };

  initVueIslands(
    { ...pages, ...components },
    (app) => {
      app.use(createPinia());
      app.use(sModalPlugin);
      app.use(VueQueryPlugin, {
        enableDevtoolsV6Plugin: true,
        queryClient: useQueryClient(),
      });
      initPrimeVueThemed(app);

      // TODO remove this i18n call when we use composition api more
      useI18nCustomer();
    },
    getEnv().config.localeCode,
  );

  leadDetails();
}

main();
